import axios from "axios";

export default axios.create({
    baseURL: "https://viacep.com.br",
    timeout: 36000,
    credentials: 'same-origin',
    headers: {
       
    }
});
 