<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex">
                    <!--<div class="question-number">4-</div>-->
                    <div class="flex-column padding-div w-100">
                        <div class="fs-32px fw-700 color-dark mb-4">Dados do endereço</div>

                        <div class="content-contents">
                            <v-form ref="form">
                                <div class="mb-4">
                                    <div class="w-100 padding-right">
                                        <div class="fs-24px fw-500 color-dark">CEP</div>
                                        <div class="d-flex ">
                                            <form class="n-form">
                                                <div class="single-input">
                                                    <v-text-field-simplemask class="input-caps" required :rules="[rules.required]" v-model="cep"
                                                                             v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: 'Digite sua resposta aqui',
      }"
                                                                             v-bind:options="{
        inputMask: '#####-###',
        outputMask: '#####-###',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" />
                                                </div>
                                            </form>
                                            <v-icon @click="searchCEP" class="ml-4 color-primary pointer">search</v-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="fs-24px fw-500 color-dark">Logradouro</div>
                                    <div class="single-input">
                                        <v-text-field required :rules="[rules.required, rules.onlyalpha]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="logradouro" />
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="d-flex justify-content-between">
                                        <div class="w-100 padding-right">
                                            <div class="fs-24px fw-500 color-dark">Cidade</div>
                                            <div class="single-input">
                                                <v-text-field required :rules="[rules.required, rules.onlyalpha]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="cidade" />
                                            </div>
                                        </div>
                                        <div class="w-100 padding-left">
                                            <div class="fs-24px fw-500 color-dark">UF</div>
                                            <div class="single-input">
                                                <v-text-field required :rules="[rules.required, rules.onlyalpha]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="uf" maxlength="2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="d-flex justify-content-between">
                                        <div class="w-100 padding-right">
                                            <div class="fs-24px fw-500 color-dark">Bairro</div>
                                            <div class="single-input">
                                                <v-text-field required :rules="[rules.required, rules.onlyalpha]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="bairro" />
                                            </div>
                                        </div>
                                        <div class="w-100 padding-left">
                                            <div class="fs-24px fw-500 color-dark">Número</div>
                                            <div class="single-input">
                                                <v-text-field required :rules="[rules.required]" placeholder="Digite sua resposta aqui" class="input-caps" v-model="numero" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="fs-24px fw-500 color-dark">Complemento</div>
                                    <div class="single-input">
                                        <v-text-field placeholder="Digite sua resposta aqui" class="input-caps" v-model="complemento" />
                                    </div>
                                </div>
                            </v-form>
                        </div>
                        <div class="content-buttons">
                            <div class="d-flex justify-content-end">
                                <button v-on:click="next" class="button-primary-bg-primary action-button">
                                    Avançar
                                    <v-icon right>east</v-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';

    import ViaCEPAPI from '../../services/ViaCEPAPI';
    import SimpleMask from "../../components/SimpleMask.vue";
    //import { mapState } from "vuex";
    export default {
        name: 's04-endereco',
        props: ['pessoa', 'visible'],
        components: {
            "v-text-field-simplemask": SimpleMask,
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: false,
            step: 1,
            cep: null,
            logradouro: null,
            cidade: null,
            uf: null,
            bairro: null,
            numero: null,
            complemento: null,
            enderecoAPI: null,
            hasErrors: false,
            rules: { 
            },
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                    this.habilitaOpcoes();
                }
            },
            habilitaOpcoes() {

                if (this.pessoa["perfil-pessoal"] === undefined) {
                    this.pessoa["perfil-pessoal"] = {};
                }
                if (this.pessoa["perfil-pessoal"].endereco === undefined) {
                    this.pessoa["perfil-pessoal"].endereco = {};
                }
                if (this.pessoa["perfil-pessoal"].endereco.CEP !== undefined) {
                    this.cep = this.pessoa["perfil-pessoal"].endereco.CEP;
                }
                if (this.pessoa["perfil-pessoal"].endereco.endereco !== undefined) {
                    this.logradouro = this.pessoa["perfil-pessoal"].endereco.endereco;
                }
                if (this.pessoa["perfil-pessoal"].endereco.numero !== undefined) {
                    this.numero = this.pessoa["perfil-pessoal"].endereco.numero;
                }
                if (this.pessoa["perfil-pessoal"].endereco.uf !== undefined) {
                    this.uf = this.pessoa["perfil-pessoal"].endereco.uf;
                }
                if (this.pessoa["perfil-pessoal"].endereco.cidade !== undefined) {
                    this.cidade = this.pessoa["perfil-pessoal"].endereco.cidade;
                }
                if (this.pessoa["perfil-pessoal"].endereco.complemento !== undefined) {
                    this.complemento = this.pessoa["perfil-pessoal"].endereco.complemento;
                }

                if (this.pessoa["perfil-pessoal"].endereco.bairro !== undefined) {
                    this.bairro = this.pessoa["perfil-pessoal"].endereco.bairro;
                }

            },
            searchCEP: function (event) {
                event.preventDefault();
                if ((this.cep == null || this.cep == "")) {
                    //this.hasErrors = true;
                    return false;
                }
                this.logradouro = "";
                this.numero = "";
                this.uf = "";
                this.cidade = "";
                this.complemento = "";
                this.bairro = "";

                var tcep = this.cep.replace(".", "");
                tcep = tcep.replace("-", "");

                ViaCEPAPI.getDetalheEndereco(tcep)
                    .then(response => {
                        var ret = response.data;
                        this.logradouro = ret.logradouro;
                        this.uf = ret.uf;
                        this.cidade = ret.localidade;
                        this.complemento = ret.complemento;
                        this.bairro = ret.bairro;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            next: async function (event) {
                event.preventDefault();

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    onlyalpha: value => {
                        const pattern = /^[A-Za-zà-úá-úâ-ûä-üã-õçñÀ-ÙÁ-ÚÂ-ÛÄ-ÜÃ-ÕÇÑ ]+$/
                        return pattern.test(value) || 'Preencha somente com letras.'
                    },
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'E-mail inválido.'
                    },
                    validdate: v => {
                        if (v == null) return true;

                        var s = v.split('/');
                        var d = new Date(+s[2], s[1] - 1, +s[0]);
                        var ok = false;
                        if (Object.prototype.toString.call(d) === "[object Date]") {
                            if (!isNaN(d.getTime()) && d.getDate() == s[0] &&
                                d.getMonth() == (s[1] - 1)) {
                                ok = true;
                                //return true;
                            }
                        }
                        if (ok && (parseInt(+s[2]) < 1000 || d.getFullYear() < 1000))
                            ok = false;

                        if (!ok)
                            return "Data inválida";

                    }
                };

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {

                        if (this.pessoa["perfil-pessoal"] === undefined) {
                            this.pessoa["perfil-pessoal"] = {};
                        }
                        if (this.pessoa["perfil-pessoal"].endereco === undefined) {
                            this.pessoa["perfil-pessoal"].endereco = {};
                        }

                        this.pessoa["perfil-pessoal"].endereco.CEP = this.cep.capitalize();
                        this.pessoa["perfil-pessoal"].endereco.endereco = this.logradouro.capitalize();
                        this.pessoa["perfil-pessoal"].endereco.numero = this.numero.capitalize();
                        this.pessoa["perfil-pessoal"].endereco.uf = this.uf.capitalize();
                        this.pessoa["perfil-pessoal"].endereco.cidade = this.cidade.capitalize();
                        this.pessoa["perfil-pessoal"].endereco.bairro = this.bairro.capitalize();

                        var tcomplemento = this.complemento;
                        if (tcomplemento!=null)
                            this.pessoa["perfil-pessoal"].endereco.complemento = tcomplemento.capitalize();
                        else 
                            this.pessoa["perfil-pessoal"].endereco.complemento = null

                        this.hasErrors = false;
                        this.$emit('next');
                    }
                })
            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
