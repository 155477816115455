<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex">
                    <!--<div class="question-number">3-</div>-->
                    <div class="flex-column padding-div w-100">
                        <div class="fs-32px fw-700 color-dark mb-5">Informe um documento de identificação</div>

                        <div class="content-contents">
                            <v-form ref="form">
                                <div class="mb-4 mt-5">
                                    <div class="fs-24px fw-500 color-dark">Que tipo de documento você gostaria de informar?</div>
                                    <div class="n-form-select">
                                        <v-select required :rules="[rules.required]" class="input-caps" placeholder="Selecione o tipo de documento" v-bind:items="itemTiposDocumento"
                                                  v-model="tipoDocumento" item-value="CODIGO" item-text="DESCRICAO"></v-select>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="fs-24px fw-500 color-dark">Digite o número do documento:</div>
                                    <div class="single-input">
                                        <v-text-field :rules="[rules.required, rules.counter]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="numDocumento" />
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="fs-24px fw-500 color-dark" v-if="fluxo=='menor18' || fluxo=='convidadomenor18' || fluxo=='aberturamenor18'">Qual é o nome da mãe do dependente?</div>
                                    <div class="fs-24px fw-500 color-dark" v-else>Qual o nome da sua mãe?</div>
                                    <div class="single-input">
                                        <v-text-field required :rules="[rules.required, rules.onlyalpha]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="nomeMae" />
                                        <!--<input type="text" class="input" id="nome" required placeholder="Digite sua resposta aqui">  -->
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="fs-24px fw-500 color-dark">UF</div>
                                    <div class="single-input">
                                        <v-text-field required :rules="[rules.required, rules.onlyalpha]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="uf" maxlength="2" />
                                        <!--<input type="text" class="input" id="nome" required placeholder="Digite sua resposta aqui">-->
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="d-flex justify-content-between">
                                        <div class="w-100 padding-right">
                                            <div class="fs-24px fw-500 color-dark">Orgão Emissor</div>
                                            <div class="single-input">
                                                <v-text-field required :rules="[rules.required]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="orgaoEmissor" />
                                                <!--<input type="text" class="input" id="nome" required placeholder="Digite sua resposta aqui">-->
                                            </div>
                                        </div>
                                        <div class="w-100 padding-left">
                                            <div class="fs-24px fw-500 color-dark">Data de Expedição</div>
                                            <div class="single-input">
                                                <v-text-field-simplemask required :rules="[rules.required, rules.validdate]" v-model="dataExpedicao"
                                                                         v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: 'DD/MM/YYYY',
      }"
                                                                         v-bind:options="{
        inputMask: '##/##/####',
        outputMask: '##/##/####',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-form>
                        </div>
                        <div class="content-buttons">
                            <div class="d-flex justify-content-end">
                                <button v-on:click="next" class="button-primary-bg-primary action-button">
                                    Avançar
                                    <v-icon right>east</v-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';

    import CorporativoAPI from '../../services/CorporativoAPI';
    import SimpleMask from "../../components/SimpleMask.vue";
    //import { mapState } from "vuex";
    export default {
        name: 's03-identificacao',
        props: ['pessoa', 'visible', 'fluxo'],
        components: {
            "v-text-field-simplemask": SimpleMask,
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: false,
            step: 1,
            tipoDocumento: null,
            numDocumento: null,
            nomeMae: null,
            uf: null,
            orgaoEmissor: null,
            dataExpedicao: null,
            itemTiposDocumento: [],
            hasErrors: false,
            rules: {
            },
        }),
        watch: {
            visible: function () {
                this.toggle();
            },
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                    this.habilitaOpcoes();
                }
            },
            habilitaOpcoes() {

                if (this.pessoa["perfil-pessoal"] === undefined) {
                    this.pessoa["perfil-pessoal"] = {};
                }
                if (this.pessoa["perfil-pessoal"].identificacao === undefined) {
                    this.pessoa["perfil-pessoal"].identificacao = {};
                }
                if (this.pessoa["perfil-pessoal"].identificacao["tipo-documento"] !== undefined) {
                    this.tipoDocumento = this.pessoa["perfil-pessoal"].identificacao["tipo-documento"];
                }
                if (this.pessoa["perfil-pessoal"].identificacao.numero !== undefined && this.pessoa["perfil-pessoal"].identificacao.numero != null && this.pessoa["perfil-pessoal"].identificacao.numero != "0") {
                    this.numDocumento = this.pessoa["perfil-pessoal"].identificacao.numero;
                }
                if (this.pessoa["perfil-pessoal"].identificacao["nome-mae"] !== undefined) {
                    this.nomeMae = this.pessoa["perfil-pessoal"].identificacao["nome-mae"];
                }
                if (this.pessoa["perfil-pessoal"].identificacao.uf !== undefined) {
                    this.uf = this.pessoa["perfil-pessoal"].identificacao.uf;
                }
                if (this.pessoa["perfil-pessoal"].identificacao["orgao-emissor"] !== undefined) {
                    this.orgaoEmissor = this.pessoa["perfil-pessoal"].identificacao["orgao-emissor"];
                }
                if (this.pessoa["perfil-pessoal"].identificacao["data-expedicao"] !== undefined) {
                    this.dataExpedicao = this.pessoa["perfil-pessoal"].identificacao["data-expedicao"];
                }

                CorporativoAPI.getTiposDocumentoID()
                    .then(response => {
                        this.itemTiposDocumento = response.data.resultado;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            next: async function (event) {
                event.preventDefault();

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    onlyalpha: value => {
                        const pattern = /^[A-Za-zà-úá-úâ-ûä-üã-õçñÀ-ÙÁ-ÚÂ-ÛÄ-ÜÃ-ÕÇÑ ]+$/
                        return pattern.test(value) || 'Preencha somente com letras.'
                    },
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'E-mail inválido.'
                    },
                    validdate: v => {
                        if (v == null) return true;

                        var s = v.split('/');
                        var d = new Date(+s[2], s[1] - 1, +s[0]);
                        var ok = false;



                        if (Object.prototype.toString.call(d) === "[object Date]") {
                            if (!isNaN(d.getTime()) && d.getDate() == s[0] &&
                                d.getMonth() == (s[1] - 1)) {
                                ok = true;
                            }
                        }

                        if (ok && (parseInt(+s[2]) < 1000 || d.getFullYear() < 1000))
                            ok = false;

                        if (!ok)
                            return "Data inválida";

                    }
                };


                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {

                        this.pessoa["perfil-pessoal"].identificacao["tipo-documento"] = this.tipoDocumento.capitalize();
                        this.pessoa["perfil-pessoal"].identificacao.numero = this.numDocumento.capitalize();
                        this.pessoa["perfil-pessoal"].identificacao["nome-mae"] = this.nomeMae.capitalize();
                        this.pessoa["perfil-pessoal"].identificacao.uf = this.uf.capitalize();
                        this.pessoa["perfil-pessoal"].identificacao["orgao-emissor"] = this.orgaoEmissor.capitalize();
                        this.pessoa["perfil-pessoal"].identificacao["data-expedicao"] = this.dataExpedicao;

                        this.hasErrors = false;
                        this.$emit('next');

                    }
                })
            },
            back: function () {
                this.$emit('back');
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

