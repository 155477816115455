import httpViaCEP from "../http-via-cep";

class ViaCEPAPI {
    async getDetalheEndereco(cep) {
        return await httpViaCEP.get(`/ws/${cep}/json`, cep);
  } 
}

export default new ViaCEPAPI();

 